import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react';

import MainLayout from '../layouts';

import { getURLQueryParam } from '../utils/url';
import { useStores } from '../contexts/rootContext';

export const Reconnect = observer(() => {
  const { phoneStore } = useStores();
  let hint = 'Reconnect';
  const subId = getURLQueryParam('subscriptionId');
  if (subId != null && subId !== '') {
    hint = 'Checking ...';
    phoneStore.loadPhone(subId);
  } else {
    hint = 'Please set subscriptionId query param';
  }
  const navigate = useNavigate();

  if (!phoneStore.waba_connected && phoneStore.frontend_url) {
    let frontEnd = phoneStore.frontend_url;
    if (!frontEnd.startsWith('https')) {
      frontEnd = `https://app.wati.io/assigned_access?subscriptionId=${subId}`;
    }
    window.location.href = frontEnd;
  }
  if (phoneStore.waba_connected) {
    navigate(`/trigger?subscriptionId=${subId}`);
  }

  if (phoneStore.error) {
    navigate(`/error?subscriptionId=${subId}`);
  }

  return (
    <MainLayout>
      <span>{hint}</span>
    </MainLayout>
  );
});
