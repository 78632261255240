import { observable, action, makeObservable } from 'mobx';
import { IErrorForRegister, IRegisterTypes, IResponse } from '../models';

import { getRegister } from '../services/registerService';

export class RegisterStore implements IRegisterTypes {
  @observable phone = '';
  response: IResponse = observable({
    account: [],
    meta: { api_status: '', version: '' },
    errors: undefined,
  });
  @observable status = 0;
  @observable reason = '';
  error: IErrorForRegister = observable({
    code: 0,
    error_subcode: 0,
    error_user_msg: '',
    error_user_title: '',
    fbtrace_id: '',
    is_transient: false,
    message: '',
    type: '',
  });

  constructor() {
    makeObservable(this);
  }

  @action loadRegister = async (subId: string, isText: boolean) => {
    try {
      const registerResponse = await getRegister(subId, isText);
      this.phone = registerResponse.phone;
      this.status = registerResponse.status;
      this.reason = registerResponse.reason;
      this.response = registerResponse.response;
      if (registerResponse.error !== undefined) {
        this.error = { ...registerResponse.error };
      }
    } catch (e) {
      this.error = { ...this.error, code: -1 };
    }
  };
}
