import { IPhoneTypes } from '../models';

import { environment } from '../environments/environment';

import { requestGet } from '../utils/request';
export const getPhone = (subId: string): Promise<IPhoneTypes> => {
  return requestGet<IPhoneTypes>(
    `${environment.GET_PHONE_INFO_HOST}/get-phone-number?sub_id=${subId}`
  );
};
