import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { CSSProperties } from 'react';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import MainLayout from '../layouts';
import ReactCodeInput from 'react-code-input';

import { getURLQueryParam } from '../utils/url';
import { useStores } from '../contexts/rootContext';

const StyledPhone = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  font-weight: 500;
`;

const StyledPhoneNumber = styled.span`
  font-style: italic;
  color: #0e71c3;
  text-decoration: underline;
`;

const StyledCode = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const inputStyle: CSSProperties = {
  margin: '4px',
  MozAppearance: 'textfield',
  width: '42px',
  borderRadius: '3px',
  height: '42px',
  backgroundColor: '#F5F6FA',
  color: '#333333',
  border: 'none',
  textAlign: 'center',
};
export const Verify = observer(() => {
  const { phoneStore, verifyStore } = useStores();

  const subId = getURLQueryParam('subscriptionId');
  const navigate = useNavigate();

  const onVerify = () => {
    if (subId != null && verifyStore.pinCode) {
      verifyStore.setLoading(true);
      verifyStore.verify(subId);
    }
  };

  const gotoError = () => {
    navigate(`/error?subscriptionId=${subId}`);
  };

  if (verifyStore.error) {
    gotoError();
  }

  if (verifyStore.api_status && phoneStore.frontend_url) {
    let frontEnd = phoneStore.frontend_url;
    if (!frontEnd.startsWith('https')) {
      frontEnd = `https://${frontEnd}`;
    }
    window.location.href = frontEnd;
  }

  const onCodeChange = (value: string) => {
    if (value.length === 6 && !isNaN(+value)) {
      verifyStore.setDisabled(false);
    } else {
      verifyStore.setDisabled(true);
    }
    verifyStore.setPinCode(value);
  };

  return (
    <MainLayout>
      <StyledPhone>
        <span>We've sent a code to</span>
        <StyledPhoneNumber>{phoneStore.display_phone_number}</StyledPhoneNumber>
      </StyledPhone>

      <span>It may take a few moments to arrive.</span>
      <StyledCode>
        <span>Please input the 6-digit verification code here.</span>
        <ReactCodeInput
          name="pinCode"
          type="tel"
          fields={6}
          inputMode="numeric"
          inputStyle={inputStyle}
          value={verifyStore.pinCode}
          onChange={onCodeChange}
        />
      </StyledCode>
      <Button
        color="green"
        size="big"
        disabled={verifyStore.isDisabled || verifyStore.isLoading}
        onClick={onVerify}
      >
        Verify
      </Button>
    </MainLayout>
  );
});
