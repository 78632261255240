import {
  createContext,
  FC,
  ReactElement,
  useContext,
  PropsWithChildren,
} from 'react';
import { PhoneStore } from '../stores/phoneStore';
import { RegisterStore } from '../stores/registerStore';
import { VerifyStore } from '../stores/verifyStore';

type RootStoreContextValue = {
  phoneStore: PhoneStore;
  registerStore: RegisterStore;
  verifyStore: VerifyStore;
};

export const StoreContext = createContext<RootStoreContextValue>(
  {} as RootStoreContextValue
);

const phoneStore = new PhoneStore();
const registerStore = new RegisterStore();
const verifyStore = new VerifyStore();

export const StoreProvider: FC<PropsWithChildren> = ({
  children,
}): ReactElement => {
  return (
    <StoreContext.Provider value={{ phoneStore, registerStore, verifyStore }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStores = () => useContext(StoreContext);
