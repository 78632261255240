import styled from 'styled-components';

import { Header } from '../components';
import SandboxTopBG from '../assets/background_top.svg';
import SandboxBottomBG from '../assets/background_bottom.svg';

import { Sizes } from '../consts';

const StyledMainLayout = styled.div`
  .sandbox__top-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;

    @media screen and (max-width: ${Sizes.mobileLg}) {
      display: none;
    }
  }
  .sandbox__bottom-image {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

    @media screen and (max-width: ${Sizes.mobileLg}) {
      display: none;
    }
  }
`;

const StyledContainer = styled.div`
  width: 500px;
  margin: auto;
  padding-top: 100px;
`;

const StyledPane = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    margin-top: 30px;
  }
`;

type Props = {
  children: JSX.Element[] | JSX.Element;
};

const MainLayout = ({ children }: Props) => {
  return (
    <StyledMainLayout>
      <img className="sandbox__top-image" src={SandboxTopBG} alt="back top" />
      <img
        className="sandbox__bottom-image"
        src={SandboxBottomBG}
        alt="back bottom"
      />
      <StyledContainer>
        <Header />
        <StyledPane>{children}</StyledPane>
      </StyledContainer>
    </StyledMainLayout>
  );
};

export default MainLayout;
