import styled from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Form, Checkbox } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import MainLayout from '../layouts';
import TriggerImg from '../assets/trigger_img.svg';
import { getURLQueryParam } from '../utils/url';
import { useStores } from '../contexts/rootContext';

const StyledPhone = styled.div`
  margin-top: 10px;
  color: #0e71c3;
  text-decoration: underline;
`;

const StyledSelectMethod = styled.div`
  color: #333333;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
`;

const StyledRadioGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: 500;
`;

export const Trigger = observer(() => {
  const { phoneStore, registerStore } = useStores();
  const [value, setValue] = useState('text');
  const subId = getURLQueryParam('subscriptionId');

  if (!phoneStore.display_phone_number && subId) {
    phoneStore.loadPhone(subId);
  }

  const navigate = useNavigate();

  const gotoVerify = () => {
    if (subId != null) {
      phoneStore.setLoading(true);
      registerStore.loadRegister(subId, value === 'text');
    }
  };

  const gotoError = () => {
    navigate(`/error?subscriptionId=${subId}`);
  };

  if (registerStore.phone === 'unregistered' && registerStore.status === 202) {
    navigate(`/verify?subscriptionId=${subId}`);
  } else if (registerStore.status === 201 && phoneStore.frontend_url) {
    let frontEnd = phoneStore.frontend_url;
    if (!frontEnd.startsWith('https')) {
      frontEnd = `https://${frontEnd}`;
    }
    window.location.href = frontEnd;
  } else if (registerStore.status === 403 || registerStore.error.code !== 0) {
    gotoError();
  }

  return (
    <MainLayout>
      <span>Verify your number via SMS or Voice Call</span>
      <StyledPhone>{phoneStore.display_phone_number}</StyledPhone>
      <img src={TriggerImg} alt="trigger" />
      <StyledSelectMethod>Select a verification method!</StyledSelectMethod>
      <StyledRadioGroup>
        <Form.Field>
          <Checkbox
            radio
            label="Text Message"
            name="checkboxRadioGroup"
            value="text"
            checked={value === 'text'}
            onChange={(e, data) => setValue(data.value + '')}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            radio
            label="Voice Call"
            name="checkboxRadioGroup"
            value="voice"
            checked={value === 'voice'}
            onChange={(e, data) => setValue(data.value + '')}
          />
        </Form.Field>
      </StyledRadioGroup>

      <Button
        color="green"
        size="big"
        onClick={gotoVerify}
        disabled={phoneStore.display_phone_number === '' || phoneStore.loading}
      >
        Send Code
      </Button>
    </MainLayout>
  );
});
