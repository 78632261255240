import { IVerifyPhone } from '../models';

import { environment } from '../environments/environment';

import { requestGet } from '../utils/request';

export const verifyPhone = (
  subId: string,
  code: string
): Promise<IVerifyPhone> => {
  const apiUrl = `${environment.GET_PHONE_INFO_HOST}/verify?sub_id=${subId}&code=${code}`;
  return requestGet<IVerifyPhone>(apiUrl);
};
