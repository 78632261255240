import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import MainLayout from '../layouts';
import { getURLQueryParam } from '../utils/url';
import { useStores } from '../contexts/rootContext';
import { observer } from 'mobx-react';

const StyledPhone = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  font-weight: 500;
`;

const StyledError = styled.div`
  color: #ff0e0e;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const Error = observer(() => {
  const { registerStore } = useStores();

  return (
    <MainLayout>
      <StyledPhone>
        <span>Ooops, something was wrong</span>
      </StyledPhone>
      <StyledError>
        <ul>
          {registerStore.response?.errors?.map((error) => (
            <li key={error.code}>
              {error.title}. &nbsp; {error.details}
            </li>
          ))}

          <div>{registerStore.error.error_user_msg}</div>
        </ul>
      </StyledError>
    </MainLayout>
  );
});
