import { observable, action, makeObservable } from 'mobx';
import { IPhoneTypes } from '../models';
import { getPhone } from '../services/phoneService';

export class PhoneStore implements IPhoneTypes {
  @observable display_phone_number = '';
  @observable waba_connected = false;
  @observable waba_id = '';
  @observable frontend_url = '';
  @observable waba_link = '';
  @observable error = false;
  @observable loading = false;

  constructor() {
    makeObservable(this);
  }

  @action setLoading = (isLoading: boolean) => {
    this.loading = isLoading;
  };

  @action loadPhone = async (subId: string) => {
    try {
      const phone = await getPhone(subId);
      this.error = false;
      this.display_phone_number = phone.display_phone_number;
      this.waba_connected = phone.waba_connected;
      this.waba_id = phone.waba_id;
      this.frontend_url = phone.frontend_url;
      this.waba_link = phone.waba_link;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  };
}
