import styled from 'styled-components';

import AppRoutes from '../routes';

import { FontFamily } from '../consts';

const StyledApp = styled.div`
  font-family: ${FontFamily.mainFont};
  color: #333333;
  font-size: 16px;
`;

export function App() {
  return (
    <StyledApp>
      <AppRoutes />
    </StyledApp>
  );
}

export default App;
