import { StatusCodes } from 'http-status-codes';

export function parseJSON(response: Response) {
  if (!response) {
    return null;
  }
  if (
    response.status &&
    (response.status === StatusCodes.NO_CONTENT ||
      response.status === StatusCodes.RESET_CONTENT)
  ) {
    return null;
  }
  return response.json();
}

function checkStatus(response: Response) {
  if (
    response.status >= StatusCodes.OK &&
    response.status < StatusCodes.MULTIPLE_CHOICES
  ) {
    return response;
  }
  const error = new Error('API called failed');

  throw error;
}

export async function requestGet<T>(url: string): Promise<T> {
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((e) => {
      console.log(e);
    });
}
