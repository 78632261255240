import { IRegisterTypes } from '../models';

import { environment } from '../environments/environment';

import { requestGet } from '../utils/request';

export const getRegister = (
  subId: string,
  isText: boolean
): Promise<IRegisterTypes> => {
  let apiUrl = `${environment.GET_PHONE_INFO_HOST}/register-gateway?sub_id=${subId}`;
  if (!isText) {
    apiUrl = `${apiUrl}&method=voice`;
  }

  return requestGet<IRegisterTypes>(apiUrl);
};
