import styled from 'styled-components';
import WATI_LOGO from '../assets/WATI_logo_full.png';

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  img {
    width: 120px;
  }
`;

export function Header() {
  return (
    <StyledHeader>
      <img src={WATI_LOGO} alt="wati logo" />
    </StyledHeader>
  );
}
