import { Route, Routes } from 'react-router-dom';

import { Trigger, Verify, Error, Reconnect } from '../pages';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/trigger" element={<Trigger />} />

      <Route path="/verify" element={<Verify />} />
      <Route path="/error" element={<Error />} />
      <Route path="/reconnect" element={<Reconnect />} />
    </Routes>
  );
}

export default AppRoutes;
