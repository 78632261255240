import { observable, action, makeObservable } from 'mobx';
import { verifyPhone } from '../services/verifyService';

export class VerifyStore {
  @observable api_status = '';
  @observable version = '';
  @observable error = false;
  @observable isDisabled = true;
  @observable isLoading = false;
  @observable pinCode = '';

  constructor() {
    makeObservable(this);
  }

  @action setDisabled = (isDisabled: boolean) => {
    this.isDisabled = isDisabled;
  };

  @action setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action setPinCode = (pinCode: string) => {
    this.pinCode = pinCode;
  };

  @action verify = async (subId: string) => {
    try {
      const verifyResponse = await verifyPhone(subId, this.pinCode);
      console.log(verifyResponse);
      this.api_status = verifyResponse.meta.api_status;
      this.version = verifyResponse.meta.version;
      this.error = false;
    } catch (e) {
      this.error = true;
    } finally {
      this.isLoading = false;
    }
  };
}
