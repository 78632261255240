export const Sizes = {
  desktop2k: '2000px',
  desktopLg: '1500px',
  desktopMd: '1280px',
  desktopSm: '1120px',
  laptopLg: '1024px',
  laptopMd: '960px',
  mobileLg: '768px',
  mobileMd: '640px',
  mobileSm: '420px',
  smallHeight: '720px',
};

export const FontFamily = {
  mainFont: "Poppins, 'Montserrat'",
  previewFont: 'Helvetica',
};
